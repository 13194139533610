import PropTypes from "prop-types";
import AccountPopover from "./AccountPopover";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import React from "react";
// import MenuBar from "../../components/menuBar";
import Container from "@mui/material/Container";

DashboardNavbar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,

  isOpenSettingbar: PropTypes.bool,
  onOpenSettingbar: PropTypes.func,
  onCloseSettingbar: PropTypes.func,
};

export default function DashboardNavbar({
  isOpenSidebar,
  onOpenSidebar,
  onCloseSidebar,
  isOpenSettingbar,
  onOpenSettingbar,
  onCloseSettingbar,
}) {

  // console.log("DashboardNavbar - isOpenSidebar : " + isOpenSidebar);

  // console.log("DashboardNavbar - isOpenSettingbar : " + isOpenSettingbar);

  const theme = useTheme();

  return (
    <Container maxWidth={"xl"}
      style={{
        width:"100%",
        paddingLeft: "5px",
        paddingRight: "5px",
        position: "fixed",
        zIndex: 2,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          borderRadius: "25px",
          marginTop: "10px",
          zIndex: "3",
          opacity: "100%",
          justifyContent: "space-between",
          backgroundColor: "white",
          zIndex: 2,
          flexFlow: "row",
          alignItems: "center",
        }}
      >
          <div
            style={{
              fontSize: "1.3rem",
              display: "flex",
              opacity: "100%",
              justifyContent: "left",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {/* <MenuBar
              isOpenSidebar={isOpenSidebar}
              onOpenSidebar={() => {
                onOpenSidebar();
              }}
              onCloseSidebar={() => {
                onCloseSidebar();
              }}
            /> */}
            
            <Link className="clickable" to={`/home/new`} style={{textDecoration:"none", background:"none"}}>
              <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"15px"}}>
                <img
                  src="/icon_new/sheru_lion.svg"
                  alt="sheru"
                  style={{
                    height: "40px",
                    marginRight: "10px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    display: "inline-block",
                  }}
                />

                <div
                  style={{ display: "flex", flexDirection: "column", gap: "0px", alignItems:"left", justifyContent:"left",
                  textDecoration:"none", }}
                >
                  <p><b
                    style={{
                      color: `${theme.palette.text.primary}`,
                      margin: "0px",
                      padding: "0px",
                      alignSelf: "center",
                      textDecoration:"none",
                      fontSize: "80%",
                    }}
                  >
                    SHERU
                  </b>
                  </p>
                  <p
                    style={{
                      color: `${theme.palette.text.disabled}`,
                      margin: "0px",
                      padding: "0px",
                      alignSelf: "center",
                      textDecoration:"none",
                      fontSize: "70%",
                    }}
                  >
                    Website content
                  </p>
                </div>
              </div>
            </Link>

          </div>

        <div
          // onClick={()=>{setIsOpen(!isOpen)}}
          style={{
            display: "flex",
            padding: "0.4rem 0.4rem",
            opacity: "100%",
            marginRight: "10px",
            borderRadius: "24px",
          }}
        >
          <AccountPopover
            isOpenSettingbar={isOpenSettingbar}
            onOpenSettingbar={() => {
              onOpenSettingbar();
            }}
            onCloseSettingbar={() => {
              onCloseSettingbar();
            }}
          />
        </div>
      </div>
    </Container>
  );
}
