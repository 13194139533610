import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

// material
import { experimentalStyled as styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
// import DashboardSidebar from './DashboardSidebar';
import PageFooter from 'src/components/footer/PageFooter'

// import Settings from 'src/components/settings';

// pages
import Login from '../../pages/authentication/Login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  transition:'0.4s ease-in',
});

const MainStyle = styled('div')(({ theme }) => ({
  // flexGrow: 1,
  overflow: 'auto',
  width:'100%',
  paddingTop:"100px",
  minHeight: '87.5vh',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  
  const theme = useTheme();
  
  const [openSideBar, setOpenSideBar] = useState(false);
  const [openSettingBar, setOpenSettingBar] = useState(false);

  var isAuthenticated = localStorage != null ? localStorage.getItem('userAuthenticated', false) : false;
  var isVerified = localStorage != null ? localStorage.getItem('userVerified', false) : false;

  const onOpenSidebar = () => {
    setOpenSideBar(true);
  }
  
  const onCloseSidebar = () => {
    setOpenSideBar(false);
  }

  const onOpenSettingbar = () => {
    setOpenSettingBar(true);
  }
  const onCloseSettingbar = () => {
    setOpenSettingBar(false);
  }

  if (isAuthenticated.toString() == "true"){
    if(isVerified.toString() == "true"){
 
      // console.log("DashboardLayout - " + "openSideBar : " + openSideBar);
      // console.log("DashboardLayout - " + "openSettingBar : " + openSettingBar);
      
      return (
        <RootStyle style={{}}>
       
          {/* <DashboardSidebar isOpenSidebar={openSideBar} onOpenSidebar={onOpenSidebar} onCloseSidebar={onCloseSidebar} 
                            isOpenSettingbar={openSettingBar} onOpenSettingbar={onOpenSettingbar} onCloseSettingbar={onCloseSettingbar} 
          /> */}
          
          <div style={{display:'flex', flexDirection:"column", width:"100%", minHeight:"100vh", alignItems:"center"}}>
            <DashboardNavbar isOpenSidebar={openSideBar} onOpenSidebar={onOpenSidebar} onCloseSidebar={onCloseSidebar}
                              isOpenSettingbar={openSettingBar} onOpenSettingbar={onOpenSettingbar} onCloseSettingbar={onCloseSettingbar} />
              <MainStyle> 
                <Outlet />
              </MainStyle>
              <PageFooter style={{ backgroundColor:"#f9f9f9" }}/>
          </div>

          {/* <Settings isOpenSettingbar={openSettingBar} onOpenSettingbar={onOpenSettingbar} onCloseSettingbar={onCloseSettingbar}/> */}

        </RootStyle>
      );
    }else{
      // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("DashboardLayout : showing login");
      return <Login />;
    }
  }else{
    // process.env.REACT_APP_ENVIRONMENT == "production" ? (()=>{})() : console.log("DashboardLayout : showing login");
    return <Login />;
  }
}
