// hooks

//

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {

  return (
    <img
        src="/icon_new/swapper_v2.png"
        alt="sheru"
        style={{
          height: "45px",
          width:"45px",
          padding:"2.5px",
          marginRight:"10px",
          marginLeft: "10px",
          display: "inline-block",
          borderRadius:"30px"
        }}
      />

    // <MAvatar
    //   // src={user.photoURL}
    //   // alt={user.displayName}
    //   // color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
    //   {...other}
    // >
    //   {/* {createAvatar(user.displayName).name} */}
    // </MAvatar>
  );
}
