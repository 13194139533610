// mock api

import './index.css'

// i18n
import './locales/i18n';

// highlight

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
// material
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// import LocalizationProvider from '@mui/x-date-pickers/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import UserContext from './contexts/UserContext';
// redux
// contexts
// import { SettingsProvider } from './contexts/SettingsContext';
// components
import { store } from './redux/store';
// import { AuthProvider } from './contexts/JWTContext';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------- -----------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <ReduxProvider store={store}>

      <UserContext>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {/* <SettingsProvider> */}
          <BrowserRouter>
            {/* <AuthProvider> */}
            <App />
            {/* </AuthProvider> */}
          </BrowserRouter>
          {/* </SettingsProvider> */}
        </LocalizationProvider>

      </UserContext>
    </ReduxProvider>

  </HelmetProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();
reportWebVitals();
