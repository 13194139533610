import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
// const GREY = {
//   0: '#FFFFFF',
//   100: '#F9FAFB',
//   200: '#F4F6F8',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#637381',
//   700: '#454F5B',
//   800: '#212B36',
//   900: '#161C24',
//   500_8: alpha('#919EAB', 0.08),
//   500_12: alpha('#919EAB', 0.12),
//   500_16: alpha('#919EAB', 0.16),
//   500_24: alpha('#919EAB', 0.24),
//   500_32: alpha('#919EAB', 0.32),
//   500_48: alpha('#919EAB', 0.48),
//   500_56: alpha('#919EAB', 0.56),
//   500_80: alpha('#919EAB', 0.8)
// };
const GREY = {
  0: '#ffffff',
  100: '#f9f9f9',
  200: '#f4f4f4',
  300: '#efefef',
  400: '#eaeaea',
  500: '#646464',
  600: '#666666',
  700: '#3b3b3b',
  800: '#353535',
  900: '#2c2c2c',
  1000: '#222222',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};


// const PRIMARY = {
//   lighter: '#C8FACD',
//   light: '#5BE584',
//   main: '#00AB55',
//   dark: '#007B55',
//   darker: '#005249',
//   contrastText: '#fff'
// };

const PRIMARY = {
  lighter: '#4c80a7',
  light: '#36709c',
  main: '#206191',
  dark: '#1c5782',
  darker: '#194d74',
  contrastText: '#fff'
};

// const SECONDARY = {
//   lighter: '#D6E4FF',
//   light: '#84A9FF',
//   main: '#3366FF',
//   dark: '#1939B7',
//   darker: '#091A7A',
//   contrastText: '#fff'
// };

const SECONDARY = {
  lighter: GREY[300],
  light: GREY[400],
  main: GREY[500],
  dark: GREY[600],
  darker: GREY[700],
  contrastText: '#fff'
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const palette = {
  light: {
    ...COMMON,
    text: { 
      primary: GREY[900], 
      secondary: GREY[700], 
      disabled: GREY[500], 
      green : "#004c00", 
      blue : "#072a4c", 
      red : "#660a0a",
      yellow:"#4c4c07"
    },
    background: { 
      paper: GREY[200], 
      default: GREY[0],
      mid:GREY[300], 
      neutral: GREY[400], 
      card:GREY[0], 
      page :GREY[100],
      green : "#80ff80",
      blue : "#8ac4ff",
      red : "#ff8c8c",
      yellow:"#ffff8c"
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    text: { 
      primary: GREY[200], 
      secondary: GREY[300], 
      disabled: GREY[400], 
      green : "#b2d8b2", 
      red :"#ffc1c1",
      blue : "#c2dcf0", 
      yellow:"#1a1401"
    },
    background: { 
      paper: GREY[800], 
      default: GREY[1000], 
      mid:GREY[700], 
      neutral: GREY[600], 
      card : GREY[1000], 
      page : GREY[900],
      green : "#195319",
      red : "#b40000",
      blue:"#1c5c8c",
      yellow:"#e1ad0f"
    },
    action: { active: GREY[500], ...COMMON.action },
  }
};

export default palette;