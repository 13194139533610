import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import  Box  from '@mui/material/Box';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  if(theme == PRIMARY_MAIN){
    return (
      <Box sx={{ width: 30, height: 30, ...sx }}>
        <div style={{ display:"flex" }}>
          <img
              src="/icon_new/sheru_lion.svg"
              alt="sheru"
              style={{
                height: "100%",
                maxWidth:"50px",
                maxHeight:"50px",
                display: "inline-block",
              }}
            />
        </div>
      </Box>
    );
  }else{
    return (
      <Box sx={{ width: 30, height: 30, ...sx }}>
        <div style={{ display:"flex" }}>
          <img
              src="/icon_new/sheru_lion.svg"
              alt="sheru"
              style={{
                height: "100%",
                maxHeight:"50px",
                maxWidth:"50px",
                display: "inline-block",
              }}
            />
        </div>
      </Box>
    );
  }
}
