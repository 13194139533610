import React, { useState, useEffect, createContext } from 'react'
import axios from 'axios';

const config = {
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`
    }
}

export const Context = createContext()

const UserContext = ({ children }) => {

    const [userObject, setUserObject] = useState(
      
    )

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/userByUid`, { uid: localStorage.getItem("userId") }, config).then((res) => {
            if (res.data) {
                setUserObject({
                    verificationStatus: res.data.verificationStatus==="verified"?true:false,
                    phoneNumber: res.data.phoneNumber,
                    profileType: res.data.profileType,
                    subroles: res.data.subroles,
                    firstName:res.data.firstName,
                    isUserBlocked: res.data.isUserBlocked, 
                    uid: res.data.uid,
                    docks:res.data.docks,
                    _id:res.data._id,
                    batteryApplicable: res.data.batteryApplicable,

                })
            }
        })
    },[localStorage.getItem("userId")])
    return(
        <Context.Provider value={{userOb:userObject}}>{children}</Context.Provider>
    )
}

export default UserContext