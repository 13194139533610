import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "src/layouts/dashboard";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
// guards
import AuthGuard from "src/guards/AuthGuard";
import CircularProgress from "@mui/material/CircularProgress";
// components
import LoadingScreen from "src/components/LoadingScreen";
import { Context } from "src/contexts/UserContext";

// ----------------------------------------------------------------------
import SecureLS from "secure-ls";

import React, { useState, useEffect, useContext } from "react";
var ls = new SecureLS();

const config = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
  },
};

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  let isDashboard = false
  if(pathname){
     isDashboard = pathname.includes("/dashboard");

  }

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const Unverified = Loadable( lazy(() => import("src/components/Unverified")));

// Authentication
const Login = Loadable( lazy(() => import("src/pages/authentication/Login")));


// Dashboard
const HomePage = Loadable( lazy(() => import("src/pages/dashboard/home-page/HomePage")));

// const IframeBanner = Loadable(lazy(()=>import('src/pages/IframeBanner')))

const ManageEmployees = Loadable( lazy(() => import("src/roles/hr/ManageEmployees")));
const ManageWebsiteMedia = Loadable( lazy(() => import("src/roles/content/ManageWebsiteMedia")));

const Page500 = Loadable( lazy(() => import("src/pages/Page500")));
const NotFound = Loadable( lazy(() => import("src/pages/Page404")));

//open pages

const QueryForm = Loadable(lazy(()=>import('src/open/query_form/QueryForm')))
const MediaAllOpen = Loadable(lazy(()=>import('src/open/media/MediaAllOpen')))
const MediaRecentOpen = Loadable(lazy(()=>import('src/open/media/MediaRecentOpen')))
const TeamsAllOpen = Loadable(lazy(()=>import('src/open/teams/TeamsAllOpen')))

//kepler
const OemKepler = Loadable(lazy(()=>import('src/open/oem/OemKepler')))
const LocationHistoryGeofence = Loadable(lazy(()=>import('src/open/oem/LocationHistoryGeofence')))

//scalebat
const ScaleBatPage = Loadable(lazy(()=>import('src/open/scalebat/ScaleBatPage')))

export default function Router() {
  const [hasAccess, setHasAccess] = useState(false);
  const { userOb: userObject } = useContext(Context);

  const GuardFunction = ({ children }) => {
    if (userObject) {
      if (
        userObject.verificationStatus &&
        userObject.verificationStatus === true
      ) {
        return <>{children}</>;
      } else if (
        userObject.verificationStatus &&
        userObject.verificationStatus === false
      ) {
        return <Navigate to="/500" />;
      } else {
        return <CircularProgress />;
      }
    } else {
      return <CircularProgress />;
    }
  };

  const RoleGuard = ({ children, url }) => {
    const [accessDataS, setAccessDataS] = useState();
    useEffect(() => {
      const accessDataRole = ls.get("accessDataRole");
      setAccessDataS(accessDataRole);
    }, []);

    if (accessDataS) {
      const urlObj = accessDataS.urls;
      const urlsArray = urlObj.map((el) => Object.keys(el)).flat();

      if (urlsArray.includes(url)) {
        const tabsArray = Object.values(urlObj)
          .filter((el) => Object.keys(el).includes(url))
          .map((el) => Object.values(el))
          .flat()[0].tabs;
        return children;
      } else {
        return <Navigate to="/500" />;
      }
    } else {
      return <CircularProgress />;
    }
  };

  return useRoutes([
    // Dashboard Routes

    // { path: "iframe", element: <IframeBanner /> },
    { path: "query-form", element: <QueryForm /> },
    { path: "team", element: <TeamsAllOpen /> },
    { path: "team.html", element: <TeamsAllOpen /> },
    { path: "media", element: <MediaAllOpen /> },
    { path: "media.html", element: <MediaAllOpen /> },
    { path: "mediaRecent", element: <MediaRecentOpen /> },
    { path: "scalebat", element: <ScaleBatPage /> },
    { path: "oemkepler", element: <OemKepler /> },
    { path: "locationhistorygeofence", element: <LocationHistoryGeofence /> },

    {
      path: "auth",
      children: [
        { path: "login", element: <Login /> },
        { path: "login-unprotected", element: <Login /> },
      
      ],
    },
    {
      path:"home",
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { path: "new", element: <HomePage /> },
        { path: "new.html", element: <HomePage /> },
        { path: "v1", element: <HomePage /> },
        { path: "v1.html", element: <HomePage /> },
      ],
    },
    {
      path:"hr",
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { path: "users", element: <ManageEmployees /> },
      ],
    },
    {
      path:"content",
      element: <AuthGuard><DashboardLayout/></AuthGuard>,
      children: [
        { path: "media", element: <ManageWebsiteMedia /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
     
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "unverified", element: <Unverified /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      children: [
        { path: "/", element: <Navigate to="/home/new" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
