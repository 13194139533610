import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
// 
import { rootReducer } from './rootReducer';
import { createStore, applyMiddleware } from "redux";
import { taskMiddleware } from "react-palm/tasks";

// ----------------------------------------------------------------------

// const store = configureStore({
//   reducer: persistReducer(rootPersistConfig, rootReducer),
//   middleware: getDefaultMiddleware({
//     serializableCheck: false,
//     immutableCheck: false
//   })
// });
const store = createStore(rootReducer,{},applyMiddleware(taskMiddleware))

const persistor = persistStore(store);

const useSelector = useReduxSelector;

const useDispatch = () => useReduxDispatch();

export { store, persistor, useSelector, useDispatch };
