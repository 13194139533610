// material
import React from "react"
import Grid from "@mui/material/Grid";
import { useTheme } from '@mui/material/styles';

//styles
import "./css/footer.css";
import "./css/footer_media.css";

export default function PageFooter() {

  const theme = useTheme();

  const OtherPageLinks = () => {
    return (
      <div className='otherLinkOuterContainer'>

        <div className='otherLinkInnerContainer'>

          <a href="https://sheru.se/terms_conditions.html" target="_blank" className='anchorLink' rel="noreferrer"> 
            <p className="p_other_page_link" style={{color:`${theme.palette.text.primary}`}}>Terms &amp; conditions</p>
          </a>

          <p className="p_gap_line"></p>

          <a href="https://sheru.se/privacy_policy.html" target="_blank" className='anchorLink' rel="noreferrer">
            <p className="p_other_page_link" style={{color:`${theme.palette.text.primary}`}}>Privacy policy</p>
          </a>

          <p className="p_gap_line"></p>

          <a href="mailto:support@sheru.se" className='anchorLink'>
            <p className="p_other_page_link" style={{color:`${theme.palette.text.primary}`}}>support@sheru.se</p>
          </a>

          <p className="p_gap_line"></p>

          <a href="tel:01141133961" className='anchorLink'>
            <p className="p_other_page_link" style={{color:`${theme.palette.text.primary}`}}>011 41133961</p>
          </a>

        </div>

      </div>
    );
  }

  return (
    <div className="footerSection" style={{backgroundColor:"#f9f9f9", width:"100%"}}>

      {/* <Grid row className="div_other_page_link_cover"> */}

        <OtherPageLinks />

      {/* </Grid> */}

      <Grid container className="div_other_page_link_cover"> 

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{margin:"0px", padding:"0px"}}>
          <div className="div_footer_sheru_logo">
            <div className="div_sheru_logo_outer">
              <div className="div_sheru_logo_inner" data-wow-delay="0.2s">
                <a href="https://sheru.se/" target="_blank" className='anchorLink' rel="noreferrer">
                  <div className="div_sheru_logo_inner2">
                    <img loading="lazy" className='img_sheru_lion' src="/icon_new/sheru_lion.svg" style={{maxWidth:"50px", maxHeight:"50px"}}/>
                  </div>
                  </a>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{margin:"0px", padding:"0px"}}>
          <div className="div_footer_social">
            <div className='div_social_media_container_outer'>
              <div className="div_social_media_container_inner" data-wow-delay="0.2s">

                <div className="f_social_icon">

                  <a href="https://www.facebook.com/SheruRoars" target="_blank" className="anchorLink" rel="noreferrer">
                    <img loading="lazy" src="/static/icon/social/facebook.png" className="imageLink"/>
                  </a>

                  <a href="https://www.instagram.com/sheruroar/" target="_blank" className="anchorLink" rel="noreferrer">
                    <img loading="lazy" src="/static/icon/social/instagram.png" className="imageLink"/>
                  </a>

                  <a href="https://twitter.com/sheruRoar" target="_blank" className="anchorLink" rel="noreferrer">
                    <img loading="lazy" src="/static/icon/social/twitter.png" className="imageLink"/>
                  </a>

                  <a href="https://www.linkedin.com/company/sheru/" target="_blank" className="anchorLink" rel="noreferrer">
                    <img loading="lazy" src="/static/icon/social/linkedin.png" className="imageLink"/>
                  </a>

                  <a href="https://medium.com/@sheruenergy" target="_blank" className="anchorLink" rel="noreferrer">
                    <img loading="lazy" src="/static/icon/social/medium.png" className="imageLink"/>
                  </a>

                  <a href="https://open.spotify.com/show/1dFLKFtTF7Umo3eakpxYM4" target="_blank" className="anchorLink" rel="noreferrer">
                    <img loading="lazy" src="/static/icon/social/spotify.png" className="imageLink"/>
                  </a>

                </div>

              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{margin:"0px", padding:"0px"}}>
          <div className="div_footer_copyright">
            <div className="div_copyright_text">
              <p className="p_copyright_text" style={{color:`${theme.palette.text.disabled}`}}>Sheru @ 2019. All right reserved</p>
            </div>
          </div>
        </Grid>

      </Grid>

    </div>
  );
}
