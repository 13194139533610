// material
import { experimentalStyled as styled } from "@mui/material/styles";
// import Card from "@mui/material/Card";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";

import Grid from "@mui/material/Grid";
import {useTheme} from "@mui/material/styles";

// import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import SecureLS from "secure-ls";
import Page from "../../components/Page";
import LoadingButton from "@mui/lab/LoadingButton";
import FirebaseApp from "firebase/app";
import { useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import "firebase/auth";
import { getSessionToken } from "../../utils/sessionManager";

import { useNavigate } from "react-router-dom";

import "firebase/auth";

var ls = new SecureLS();

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const config = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
  },
};


// ----------------------------------------------------------------------

export default function Login() {

  
  const theme = useTheme();

  var [userCurrentState, setUserCurrentState] = useState("phone");
  var [isSubmitting, setSubmitting] = useState(false);
  var [phoneNumber, setPhoneNumber] = useState(null);
  const [params, setSearchParams] = useSearchParams();
  var [otpNumber, setOtpNumber] = useState(null);
  var [askName, setAskName] = useState();
  var [name, setName] = useState("");
  const location = useLocation();
  var [customToken, setCustomToken] = useState(null);
  
  var [isVerified, setIsVerified] = useState(
    localStorage.getItem("userVerified")
      ? localStorage.getItem("userVerified")
      : false
  );

  var [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("userAuthenticated")
      ? localStorage.getItem("userAuthenticated")
      : false
  );
  const navigate = useNavigate();


    
  const ContentStyle = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: 500,
    backgroundColor: "white",
    borderRadius:"30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 2, 2),
    padding: theme.spacing(5, 5),
  }));

  const SectionStyle = styled("div")(({ theme }) => ({
    maxWidth: 600,
    margin: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(5, 5),
  }));

  if (
    isAuthenticated !== null &&
    isAuthenticated.toString() == "true" &&
    isVerified !== null &&
    isVerified.toString() == "true"
  ) {
    navigate("/home/new");
  }

  const uid = params.get("uid");

  useEffect(() => {
    if (uid) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/user/getCustomToken`,
          { uid },
          config
        )
        .then((res) => {
          const token = res.data.customToken;
          signInWithCustomToken(token);
        });
    }
  }, [uid]);

  const submitPhoneToMongo = async (event, phoneVal) => {

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/userByPhone`,
        { phoneVal },
        config
      )
      .then((res) => {
        if (res.data) {
          const response = res.data;
          if (!response.exists) {
            setAskName(true);
            submitPhone(event, phoneVal);
          } else {
            setAskName(false);
            submitPhone(event, phoneVal);
          }
        } else {
          setUserCurrentState("phone");
        }
      })
      .catch((err) => {
        setUserCurrentState("phone");
      });
  };

  const submitPhone = async (event, phoneVal) => {

    setSubmitting((isSubmitting) => true);
    setUserCurrentState((isSubmitting) => "phone");

    if(event != null && event != undefined) event.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          deviceType: "sheruApp",
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
          userType: "swapper",
          "Access-Control-Allow-Origin": "*",
          latitude: "empty",
          longitude: "empty",
          zoneCode: "empty",
          zoneLat: "empty",
          zoneLong: "empty",
        },
        body: JSON.stringify({
          phoneNumber: phoneVal,
        }),
      };
      const url =
        process.env.REACT_APP_ENVIRONMENT == "production"
          ? "https://tezz.sheru.se/" + "swapper/loginOtp"
          : "https://tezztest.sheru.se/" + "swapper/loginOtp";
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitPhone  : url : " + url);
      fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          // process.env.REACT_APP_ENVIRONMENT == "production"
          //   ? (() => {})()
          //   : console.log(
          //       "Login - submitPhone  : json : " + JSON.stringify(responseData)
          //     );
          setSubmitting((isSubmitting) => false);
          setUserCurrentState((isSubmitting) => "otp");
        });
    } catch (error) {
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitPhone  : error : " + error.message);
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitPhone  : error : " + error.code);
      // console.error(error);
      alert(error?.message);
      setSubmitting((isSubmitting) => false);
      setUserCurrentState("phone");
    }
  };

  const submitOtpMongo = async (event, otpVal, nameVal ) => {
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - submitOtp  : ");

    setSubmitting((isSubmitting) => true);
    setUserCurrentState((isSubmitting) => "otp");

    if(event != null && event != undefined) event.preventDefault();

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          deviceType: "sheruApp",
          userType: "swapper",
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
          "Access-Control-Allow-Origin": "*",
          latitude: "empty",
          longitude: "empty",
          zoneCode: "empty",
          zoneLat: "empty",
          zoneLong: "empty",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          otp: otpVal,
        }),
      };
      const url =
        process.env.REACT_APP_ENVIRONMENT == "production"
          ? "https://tezz.sheru.se/" + "swapper/verifyLoginOtp"
          : "https://tezztest.sheru.se/" + "swapper/verifyLoginOtp";
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitOtp  : url : " + url);
      fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          // process.env.REACT_APP_ENVIRONMENT == "production"
          //   ? (() => {})()
          //   : console.log(
          //       "Login - submitOtp  : json : " + JSON.stringify(responseData)
          //     );
          // console.log(responseData);
          var requestData = responseData.requestData;
          if (requestData != null) {
            var status = requestData.status;
            if (status != null && status === "success") {
              var customTokenVal = requestData.customToken;
              if (customTokenVal != null && !(customTokenVal == "")) {
                // process.env.REACT_APP_ENVIRONMENT == "production"
                //   ? (() => {})()
                //   : console.log(
                //       "Login : updateOtpData : It's a success : " +
                //         customTokenVal
                //     );
                const resData = {
                  firstName: nameVal,
                  phoneNumber,
                };
                axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_URL}/user/updateSwapperName`,
                    resData,
                    config
                  )
                  .then((res) => {
                    if (res.data) {
                      const response = res.data;
                      if (response.success) {
                        setCustomToken((customToken) => customTokenVal);
                        signInWithCustomToken(customTokenVal);
                        return;
                      } else {
                        alert("some error occured");
                      }
                    }
                  });
                // setCustomToken(customToken => customTokenVal);
                // signInWithCustomToken(customTokenVal);
                // return;
              } else {
                alert(
                  requestData.message != null
                    ? requestData.message
                    : "Some error occured, please try again!"
                );
                setSubmitting((isSubmitting) => false);
                setUserCurrentState("phone");
              }
            } else {
              alert(
                requestData.message != null
                  ? requestData.message
                  : "Some error occured, please try again!"
              );
              setSubmitting((isSubmitting) => false);
              setUserCurrentState("phone");
            }
          } else {
            alert(responseData.message);
            setSubmitting((isSubmitting) => false);
            setUserCurrentState("phone");
          }
        });
    } catch (error) {
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitOtp  : error : " + error.message);
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitOtp  : error : " + error);
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitOtp  : error : " + error.code);
      console.error(error);
      alert(error?.message);

      setSubmitting((isSubmitting) => false);
      setUserCurrentState("phone");
    }
  };
  
  const submitOtp = async (event, otpVal) => {
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - submitOtp  : ");
    if(event != null && event != undefined)  event.preventDefault();

    setSubmitting((isSubmitting) => true);
    setUserCurrentState((isSubmitting) => "otp");

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          deviceType: "sheruApp",
          userType: "swapper",
          "Access-Control-Allow-Origin": "*",
          latitude: "empty",
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
          longitude: "empty",
          zoneCode: "empty",
          zoneLat: "empty",
          zoneLong: "empty",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          otp: otpVal,
        }),
      };
      const url =
        process.env.REACT_APP_ENVIRONMENT == "production"
          ? "https://tezz.sheru.se/" + "swapper/verifyLoginOtp"
          : "https://tezztest.sheru.se/" + "swapper/verifyLoginOtp";
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitOtp  : url : " + url);
      fetch(url, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          // process.env.REACT_APP_ENVIRONMENT == "production"
          //   ? (() => {})()
          //   : console.log(
          //       "Login - submitOtp  : json : " + JSON.stringify(responseData)
          //     );
          // console.log(responseData);
          var requestData = responseData.requestData;
          if (requestData != null) {
            var status = requestData.status;
            if (status != null && status === "success") {
              var customTokenVal = requestData.customToken;
              if (customTokenVal != null && !(customTokenVal == "")) {
                // process.env.REACT_APP_ENVIRONMENT == "production"
                //   ? (() => {})()
                //   : console.log(
                //       "Login : updateOtpData : It's a success : " +
                //         customTokenVal
                //     );
                setCustomToken((customToken) => customTokenVal);
                signInWithCustomToken(customTokenVal);
                return;
              } else {
                alert(
                  requestData.message != null
                    ? requestData.message
                    : "Some error occured, please try again!"
                );
                setSubmitting((isSubmitting) => false);
                setUserCurrentState("phone");
              }
            } else {
              alert(
                requestData.message != null
                  ? requestData.message
                  : "Some error occured, please try again!"
              );
              setSubmitting((isSubmitting) => false);
              setUserCurrentState("phone");
            }
          } else {
            alert(responseData.message);
            setSubmitting((isSubmitting) => false);
            setUserCurrentState("phone");
          }
        });
    } catch (error) {
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitOtp  : error : " + error.message);
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitOtp  : error : " + error);
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitOtp  : error : " + error.code);
      // console.error(error);
      alert(error?.message);

      setSubmitting((isSubmitting) => false);
      setUserCurrentState("phone");
    }
  };

  const submitVerify = async () => {
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - submitVerify  : ");

    setSubmitting((isSubmitting) => true);
    setUserCurrentState((isSubmitting) => "verify");

    const sessionToken = localStorage.getItem("sessionToken");
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - submitVerify : sessionToken  : " + sessionToken);

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: customToken,
          "Content-Type": "application/json",
          deviceType: "sheruApp",
          userType: "swapper",
          "Access-Control-Allow-Origin": "*",
          latitude: "empty",
          longitude: "empty",
          zoneCode: "empty",
          zoneLat: "empty",
          zoneLong: "empty",
        },
        body: JSON.stringify({
          inviteCode: "empty",
          fcmToken: "empty",
          phoneNo: phoneNumber,
          deviceType: "Web",
          entity: "Swap",
          deviceId: "webServer",
        }),
      };
      const url =
        process.env.REACT_APP_ENVIRONMENT == "production"
          ? "https://tezz.sheru.se/" + "swapper/phoneAuth"
          : "https://tezztest.sheru.se/" + "swapper/phoneAuth";
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("Login - submitVerify  : url : " + url);
      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((responseText) => {
          // process.env.REACT_APP_ENVIRONMENT == "production"
          //   ? (() => {})()
          //   : console.log("Login - submitVerify  : response : " + responseText);
          localStorage.setItem("userVerified", false);
          // console.log("396");
          verifyRoles();
        });
    } catch (error) {
      console.error(error);
      alert(error?.message);
      setSubmitting((isSubmitting) => false);
      setUserCurrentState("phone");
    }
  };

  const verifyRoles = async () => {
    // console.log("verifyRoles");
    setSubmitting((isSubmitting) => true);
    setUserCurrentState((isSubmitting) => "userRoles");

    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("verify - verifyRoles  : ");
    const sessionToken = localStorage.getItem("sessionToken");
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("verify - sessionToken  : " + sessionToken);

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
          "Content-Type": "application/json",
          deviceType: "sheruApp",
          userType: "swapper",
          "Access-Control-Allow-Origin": "*",
          latitude: "empty",
          longitude: "empty",
          zoneCode: "empty",
          zoneLat: "empty",
          zoneLong: "empty",
        },
        body: JSON.stringify({
          uid: localStorage.getItem("userId"),
        }),
      };
      const url =
        process.env.REACT_APP_ENVIRONMENT == "production"
          ? "https://tezz.sheru.se/" + "user/getSwapperVerification"
          : "https://tezztest.sheru.se/" + "user/getSwapperVerification";
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log("verify - verifyRoles  : url : " + url);
      await fetch(url, requestOptions)
        .then((response) => response.text())
        .then((responseText) => {
          // process.env.REACT_APP_ENVIRONMENT == "production"
          //   ? (() => {})()
          //   : console.log("verify - verifyRoles  : response : " + responseText);
          // console.log(responseText);
          if (JSON.parse(responseText).verificationStatus === "verified") {
            localStorage.setItem("userVerified", true);
            setSubmitting((isSubmitting) => false);
            // console.log("Verified");
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/user/getUserAccessData`,
                { uid: localStorage.getItem("userId") },
                config
              )
              .then((res) => {
                // console.log(res.data);
                const { accessDataRole, accessDataSubrole } = res.data;
                ls.set("accessDataRole", accessDataRole);
                ls.set("accessDataSubrole", accessDataSubrole);
              });

            // console.log(location.pathname, "location");

            // navigate(location.pathname==="/dashboard"? "/dashboard/app" : location.pathname);
            navigate("/home/new");
          } else {
            localStorage.setItem("userVerified", false);
            setSubmitting((isSubmitting) => false);
            setUserCurrentState("phone");
            // console.log("Unverified");
            navigate("/500");
          }
        });
    } catch (error) {
      alert(error?.message);
      setSubmitting((isSubmitting) => false);
      setUserCurrentState("phone");
    }
  };

  const signInWithCustomToken = async (customToken) => {
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - signInWithCustomToken");
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - signInWithCustomToken : token : " + customToken);
    var customTokenVal = customToken;
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - signInWithCustomToken  : " + phoneNumber);
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - signInWithCustomToken  : " + otpNumber);
    // process.env.REACT_APP_ENVIRONMENT == "production"
    //   ? (() => {})()
    //   : console.log("Login - signInWithCustomToken  : " + customToken);

    try {
      // console.log("Firebase app")
      await FirebaseApp.auth()
        .setPersistence(FirebaseApp.auth.Auth.Persistence.LOCAL)
        .then(
          await FirebaseApp.auth()
            .signInWithCustomToken(customToken)
            .then((res) => {
              // process.env.REACT_APP_ENVIRONMENT == "production"
              //   ? (() => {})()
              //   : console.log("Login - signInWithCustomToken  : success ");
              // process.env.REACT_APP_ENVIRONMENT == "production"
              //   ? (() => {})()
              //   : console.log(res);
              // process.env.REACT_APP_ENVIRONMENT == "production"
              //   ? (() => {})()
              //   : console.log(res);
              localStorage.setItem("userAuthenticated", true);
              localStorage.setItem("userId", res.user.uid);
              getSessionToken();
              submitVerify();
            })
        )
        .catch((error) => {
          // process.env.REACT_APP_ENVIRONMENT == "production"
          //   ? (() => {})()
          //   : console.log(
          //       "Login - signInWithCustomToken - error code : " + error.code
          //     );
          // process.env.REACT_APP_ENVIRONMENT == "production"
          //   ? (() => {})()
          //   : console.log(
          //       "Login - signInWithCustomToken - error message : " +
          //         error.message
          //     );
          //     console.log(error)
          alert(error.message);
          setSubmitting((isSubmitting) => false);
          setUserCurrentState("phone");
        });
    } catch (error) {
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log(
      //       "Login - signInWithCustomToken - error code : " + error.code
      //     );
      // process.env.REACT_APP_ENVIRONMENT == "production"
      //   ? (() => {})()
      //   : console.log(
      //       "Login - signInWithCustomToken - error message : " + error.message
      //     );
      alert(error.message);
      // console.log(error)

      setSubmitting((isSubmitting) => false);
      setUserCurrentState("phone");
    }
  };

  const LeftView = () => {
    return(
      <Grid item xs={12} sm={12} md={5} ls={5} xl={5} sx={{ display: { xs: 'none', sm: 'none', md: 'flex', ls: 'flex', sx: 'flex' }, height:{xs: '0vh', sm: '0vh', md: '100vh', ls: '100vh', sx: '100vh'}, flexDirection:"column", alignItems:"center", justifyContent:"center" }}>
          <SectionStyle>
            <Typography variant="h4" sx={{ px: 5}}>
              Hi, Welcome Back
            </Typography>
            <img
              src="/static/illustrations/login_v2.png"
              style={{width:"100%"}}
              alt="login"
            />
          </SectionStyle>
      </Grid>
    )
  }

  const OtpSubmitClicked = (event, otpVal) => {
    setOtpNumber(otpVal);
    submitOtp(event, otpVal)
  }

  const OtpAndNameSubmitClicked = (event, otpVal, nameVal) => {
    setOtpNumber(otpVal);
    setName(nameVal);
    submitOtpMongo(event, otpVal, nameVal)
  }

  const OtpView = () => {
    var [otpLocal, setOtpLocal] = useState(null);
    var [nameLocal, setNameLocal] = useState(name);

    return(
      <div>
        <Typography sx={{ color: "text.secondary", mb: 2 }}>
          Enter otp below.
        </Typography>
        
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="otp"
            type="number"
            label="OTP"
            value={otpLocal}
            disabled = {userCurrentState != "otp"}
            onChange={(event) => {
              // process.env.REACT_APP_ENVIRONMENT == "production"
              //   ? (() => {})()
              //   : console.log(
              //       "otp : " + +JSON.stringify(event.target.value)
              //     );
                  setOtpLocal((otpLocal) => event.target.value);
            }}
          />

          {!isSubmitting && askName && (
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              autoComplete="name"
              label="Name"
              value={nameLocal}
              onChange={(event) => {
                setNameLocal(event.target.value);
              }}
            />
          )}

          {!isSubmitting &&
            askName &&
            nameLocal !== null &&
            nameLocal !== "" ? (
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{ marginTop: "20px" }}
                onClick={(e) => OtpAndNameSubmitClicked(e, otpLocal, nameLocal)}
              >
                Submit Name and OTP
              </Button>
            ) : (
              <></>
            )}

            {!isSubmitting && !askName && (
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                style={{ marginTop: "20px" }}
                onClick={(e) => OtpSubmitClicked(e, otpLocal)}
              >
                Submit OTP
              </Button>
            )}

          {isSubmitting &&
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={true}
              style={{ marginTop: "20px" }}
            >
              Loading please wait
            </LoadingButton>
          }

        </Stack>

      </div>
    )
  }


  const submitPhoneClicked = (event, phoneVal) => {
    setPhoneNumber(phoneVal);
    submitPhoneToMongo(event, phoneVal);
  }

  const EnterphoneView = () => {

    var [phoneNumberLocal, setPhoneNumberLocal] = useState(null);
    return(
      <div>
        <Typography sx={{ color: "text.secondary", mb: 2 }}>
          Enter your phone number below.
        </Typography>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="phone"
            type="number"
            label="Phone number"
            value={phoneNumberLocal}
            disabled = {userCurrentState != "phone"}
            onChange={(event) => {
              // process.env.REACT_APP_ENVIRONMENT == "production"
              //   ? (() => {})()
              //   : console.log(
              //       "phone : " + JSON.stringify(event.target.value)
              //     );
              setPhoneNumberLocal((phoneNumberLocal) => event.target.value);
            }}
          />
        </Stack>

        {isSubmitting ? (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={true}
              style={{ marginTop: "20px" }}
            >
              Loading please wait
            </LoadingButton>
          ) : (
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ marginTop: "20px", borderRadius:"30px" }}
              onClick={(e) => submitPhoneClicked(e, phoneNumberLocal)}
            >
              Submit Phone Number
            </Button>
          )}
      </div>
    )
  }

  const VerifyingView = () => {
    return(
      <div style={{ padding: "20px", gap:"10px"}}>
        <p>Verifying account details, please wait</p>

        {isSubmitting &&
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={true}
            style={{ marginTop: "20px" }}
          >
            Loading please wait
          </LoadingButton>
        }

        {!isSubmitting && (
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ marginTop: "20px" }}
              onClick={(e) => submitVerify()}
            >
              Verify
            </Button>
          )}

      </div>
    )
  }

  const RolesView = () => {
    return(
      <div style={{ padding: "20px", gap:"10px" }}>
        
        <p>Fetching user assigned roles, please wait</p>

        {isSubmitting &&
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={true}
            style={{ marginTop: "20px" }}
          >
            Loading please wait
          </LoadingButton>
        }

        {!isSubmitting && (
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              style={{ marginTop: "20px" }}
              onClick={(e) => verifyRoles()}
            >
              Get roles
            </Button>
          )}
      </div>
    )
  }


  const RightView = () => {
    return (
      <Grid item xs={12} sm={12} md={7} ls={7} xl={7} style={{minHeight:"100vh", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <div style={{width:"100%", display:"flex", flexDirection:"row", alignItems:"center", gap:"10px"}}>
              {/* <img src="/icon_new/sheru_lion.svg" style={{display:"block", maxWidth:"35px", maxHeight:"35px", alignSelf:"center"}}/> */}
              <Typography variant="h4" gutterBottom style={{alignSelf:"center", textAlign:'center', padding:"0px"}}> 
                Sheru Console
              </Typography>
              {/* <Typography variant="h4" gutterBottom>
                Sign in 
              </Typography> */}
            </div>
          
          </Stack>

          {
            userCurrentState == "otp" ? (<OtpView/>) : 
            ( userCurrentState == "verify" ? <VerifyingView/> : 
            ( userCurrentState == "userRoles" ? <RolesView/> : 
            <EnterphoneView/>))
          }
         
        </ContentStyle>
      </Grid>
    )
  }

  return (
    <Page style={{   width:"100%", backgroundImage: "linear-gradient(to left, #e9f2f9, #ffffff)" }}>
      <Container maxWidth={"xl"}>
        <Grid container>
          <LeftView/>
          <RightView/>
        </Grid>
      </Container>
    </Page>
  );
}
